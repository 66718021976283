import Link from 'next/link';

import { FEATURED_CATEGORY_ID } from '@/constants';
import { useThemeSettings } from '@/hooks';
import type { StoryWithImage } from 'types';

import CategoriesList from '../CategoriesList';
import StoryImage from '../StoryImage';
import StoryPublicationDate from '../StoryPublicationDate';

import styles from './StoryCard.module.scss';

type Props = {
    story: StoryWithImage;
};

function StoryCard({ story }: Props) {
    const { title, subtitle } = story;
    const categories = story.categories.filter((c) => c.id !== FEATURED_CATEGORY_ID);
    const { showDate, showSubtitle } = useThemeSettings();

    return (
        <div className={styles.container}>
            <Link href={`/${story.slug}`} locale={false} className={styles.imageWrapper}>
                <StoryImage
                    story={story}
                    className={styles.image}
                    placeholderClassName={styles.placeholder}
                />
            </Link>
            <div className={styles.content}>
                {categories.length > 0 && (
                    <div className={styles.categories}>
                        <CategoriesList categories={categories} isStatic />
                    </div>
                )}
                <h3 className={styles.title}>
                    <Link href={`/${story.slug}`} locale={false} className={styles.titleLink}>
                        {title}
                    </Link>
                </h3>

                {subtitle && showSubtitle && (
                    <p className={styles.subtitle}>
                        <Link href={`/${story.slug}`} locale={false} className={styles.titleLink}>
                            {subtitle}
                        </Link>
                    </p>
                )}

                {showDate && (
                    <p className={styles.date}>
                        <StoryPublicationDate story={story} />
                    </p>
                )}
            </div>
        </div>
    );
}

export default StoryCard;
