import { toUploadcareImageDetailsShape } from '@/utils';

export const LOGO_IMAGE = toUploadcareImageDetailsShape({
    size: 54517,
    total: 54517,
    done: 54517,
    uuid: '7993500e-abef-4e0b-8b36-0c19097b81d6',
    file_id: '7993500e-abef-4e0b-8b36-0c19097b81d6',
    original_filename: 'logo.png',
    is_image: true,
    is_stored: true,
    image_info: {
        dpi: [300, 300],
        width: 2965,
        format: 'PNG',
        height: 856,
        sequence: false,
        color_mode: 'RGBA',
        orientation: null,
        geo_location: null,
        datetime_original: null,
    },
    video_info: null,
    content_info: {
        mime: { mime: 'image/png', type: 'image', subtype: 'png' },
        image: {
            dpi: [300, 300],
            width: 2965,
            format: 'PNG',
            height: 856,
            sequence: false,
            color_mode: 'RGBA',
            orientation: null,
            geo_location: null,
            datetime_original: null,
        },
    },
    is_ready: true,
    filename: 'logo.png',
    mime_type: 'image/png',
    metadata: {},
});
